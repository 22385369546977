$.ajaxSetup({
	headers: {
		'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
	}
}); 

var Core = {
    init: function () {
        Core.initAjaxForm();
        Core.initAjaxLinks();
    },

    mergeOptions: function (obj1, obj2) {
        var obj3 = {}
        for (var attrname in obj1) {
            obj3[attrname] = obj1[attrname]
        }
        for (var attrname in obj2) {
            obj3[attrname] = obj2[attrname]
        }
        return obj3
    },

    ajaxRequest: function (options) {
        var defaultOptions = {
            'method': 'post',
            'success': ResponseProcessor.process
        }

        var finalOptions = Core.mergeOptions(defaultOptions, options)

        try {
            if (typeof finalOptions.url === 'undefined') {
                throw 'Missing param "url"'
            }
        } catch (err) {
            console.log(err)
            return false
        }

        $.ajax({
            'url': finalOptions.url,
            'data': finalOptions.data,
            'method': finalOptions.method,
            'type': 'json',
            'success': function (response) {
                try {
                    finalOptions.success(response)
                } catch (err) {
                    console.log(err)
                }
            }
        })
    },

    initAjaxForm: function () {
        $('form.ajax-form').off('submit.ajax-form').on('submit.ajax-form', this.handleAjaxSubmit);
    },

    initAjaxLinks: function () {
        $('.ajax-link').off('click.ajax-link').on('click.ajax-link', this.handleAjaxLink);
    },

    handleAjaxLink: function (e) {
        e.preventDefault();
        var inst = $(this)
        var href = inst.prop('href') || inst.data('href')

        var method = inst.data('method')
        var confirmText = inst.data('confirm')
        if (confirmText !== undefined) {
            if (!confirm(confirmText)) {
                return false
            }
        }
        if (method === undefined || method !== 'post') {
            method = 'get'
        }

        Core.ajaxRequest({
            'url': href,
            'data': {},
            'method': method
        })
    },

    handleAjaxSubmit: function (e) {
        e.preventDefault();
        var inst = $(this)

        var method = inst.prop('method')
        if (method === undefined || method !== 'post') {
            method = 'get'
        }

        var action = inst.prop('action')
        var data = inst.serialize()

        Core.ajaxRequest({
            'url': action,
            'data': data,
            'method': method
        })
    }
}

var ResponseProcessor = {

    process: function (response) {
        methods = ['remove'];
        for (i in methods) {
            method = methods[i];
            if (response[method]) {
                for (var key in response[method]) {
                    $(response[method][key])[method]();
                }
            }
        }


        methods = ['html', 'replaceWith', 'prepend', 'insertBefore', 'insertAfter', 'before', 'after', 'append'];
        for (i in methods) {
            method = methods[i];
            if (response[method]) {
                for (var key in response[method]) {
                    $(key)[method](response[method][key]);
                }
            }
        }

        if (response.modal) {
            for (var key in response.modal) {
                $(key + ' .modal-body').html(response.modal[key]);
                $(key).modal('show');
            }
        }

        if (response.call) {
            eval(response.call);
        }

        if(response.redirect) {
            window.location.href = response.redirect;
        }

        Core.init();

    }
}

$(document).ready(function() {
   Core.init();
});