$("document").ready(function(){

   /* --------------------------------------------------
   / Horse Sub Type Drop Down
   -------------------------------------------------- */
   $("#horse_type").change(function(){

      var select_horse_type = $("#horse_type").val();

      if(select_horse_type===""){

         $("#horse_sub_type").prop('disabled', true);
         $("#horse_sub_type").html('<option></option>');

      } else {

         $("#horse_sub_type").prop('disabled', true);
         $("#horse_sub_type").html('<option>Loading...</option>');

         $.ajax({
               type: "POST",
               url: $("#horse_type").data('url'),
               data: { 'horse_type': $("#horse_type").val()  },
               success: function(data){
                   $("#horse_sub_type").empty();
                   $("#horse_sub_type").append('<option value="">Any</option>');
                   $.each(data, function(i, d) {
                       $("#horse_sub_type").append('<option value="' + d.typeID + '">' + d.type_name + '</option>');
                   });
                   $("#horse_sub_type").prop('disabled', false);
               }
           });
      }
   });


   function resize_profile_page(){
	   	//console.log($("#profile-photo-container").height());
   		//$("#profile-photo-container").height($("#profile-text-container").height()+200);
   		//console.log($("#profile-photo-container").height());
   }

   $( window ).resize(function() {
		resize_profile_page();
   });


   resize_profile_page();



   /* Profile Main Image Update */
   $(".profile-thumb-rollover").click(function(){

      $("#profile-main-photo").data("new-image", $(this).data('url'));

      $("#profile-video-player").hide();
      $("#profile-video-player").attr("src", "");

      $("#profile-main-photo").fadeOut( "fast", function() {
         $("#profile-main-photo").attr("src", $("#profile-main-photo").data("new-image"));
         $("#profile-main-photo").fadeIn("fast");
      });
   });

   $(".profile-video-rollover").click(function(){

       var url = $(this).data('video-url');
       $(".profile-photo").fadeOut("fast");

       $("#profile-video-player").fadeOut("fast", function() {
           $('#profile-video-player').empty();

           if(url.match(/youtube/g)) {
               $('<iframe>', {
                   src: url,
                   frameborder: 0,
                   scrolling: 'no',
                   height: '300px',
                   width: '100%'
               }).appendTo($('#profile-video-player'));
           }else{
               $('<video />', {
                   src: url,
                   type: 'video/mp4',
                   controls: true,
                   height: '300px',
                   width: '100%'
               }).appendTo('#profile-video-player');
           }

          $("#profile-main-photo").fadeOut("fast");
          $("#profile-video-player").fadeIn("fast");
       });

   });

   $("#profile-video-player").hide();


   if($('#validate-form').length>0){
	   $('#validate-form').parsley();
   }
   
   $('.menu_item_parent').children('.submenu, .submenu_simple').hide();
   
   $('.menu_item_parent').mouseenter(function() {
       if(! window.matchMedia('(max-width: 1030px)').matches) {
           var submenu = $(this).children('.submenu, .submenu_simple');
           submenu.fadeTo(100,1,function(){
               $(this).slideDown(100);
               if (!submenu.hasClass('menu-init')) {
                   submenu.addClass('menu-init');
                   if(! submenu.hasClass('submenu_simple')) {
                       submenu.find("a").css("min-height", "0px"), submenu.children("ul").each(function() {
                           tmpEle = $(this);
                           var e = tmpEle.outerHeight();
                           tmpEle.find("> li > a").css("min-height", e + "px");
                       });
                   }
               }
           });
       }
   });
   
   $('.menu_item_parent').mouseleave(function() {
		$('.submenu').stop();
		$(this).children('.submenu, .submenu_simple').slideUp(100);
   });
   
   $("[data-offset-top]").on("affixed.bs.affix",function () {
	  $(this).removeAttr("style");
	});
});

function menuProcess() {
}


function scrollToElement(ele){
	$('html, body').animate({
        scrollTop: ele.offset().top - 15
    }, 500);
}

function menuMinify() {
    var e = $(window).scrollTop(),
        n = $(".mainheader").outerHeight();
    e >= n ? $("body").addClass("scroll_header") : $("body").removeClass("scroll_header")
}
var tmpEle;
$(document).ready(function() {
    menuProcess(), $(".menuToggle").on("click", function (e) {
        $("body").removeClass("searchOpen"), $("body").toggleClass("menuOpen")
    }), $(".searchToggle").on("click", function (e) {
        $("body").removeClass("menuOpen"), $("body").toggleClass("searchOpen")
    }), $(".menu_item_parent > span, .menu_item_parent > a").on("click", function () {
        $(this).parent().toggleClass("menu_item_open").siblings().removeClass("menu_item_open")
    })
}), $(window).resize(function() {
    menuProcess()
}), $(window).scroll(function() {
    menuMinify()
});
