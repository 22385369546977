$(document).ready(function () {
    bindAdvertWizard();
});

function bindAdvertWizard() {
    advertWizardFileManager();
    advertWizardCompetitions();
    advertWizardValidation();
    advertWizardSetSubmitState();
    advertWizardSwitchToAdvertMode();

    $('.advert-wizard-nav').on('click', function () {
        var inst = $(this);
        advertWizardStep(inst.data('target'), ! inst.data('ignore-validation'));
    });

    $('#advert-submit').on('click', function () {
        advertWizardSubmit();
    });

    $(document).off('AdvertWizardV2Controller:advertSaved').on('AdvertWizardV2Controller:advertSaved', function (event, result) {
        $('.fn-advert-saved').removeClass('hidden');
        setTimeout(function() {
            $('.fn-advert-saved').addClass('hidden');
        }, 3000);

        if(result.reloadPaymentFormUrl) {
            Core.ajaxRequest({url: result.reloadPaymentFormUrl, method: 'get'});
        }
    });


    $('.advert-wizard-section.active').removeClass('active');
    $('.advert-wizard-section:not(.complete):first').addClass('active');

    if($('#advert-launched').length) {
        $('#advert-launched').modal();
    }

    $('.fn-syndicate-select').off('change').on('change', function() {
        $('.fn-horse-price').toggleClass('hidden');

        $('.fn-horse-price').each(function() {
            if($(this).hasClass('hidden')) {
                $(this).find(':input').attr('readonly', 'readonly').trigger('change');
            } else {
                $(this).find(':input').removeAttr('readonly').trigger('change');
            }
        });

        var formToSubmit = $(this).closest('form');
        if (formToSubmit.find('input[name="horse_id"]').length) {
            $('#advert-form').trigger('submit');
        }
    });
}

function advertWizardStep(step, highlightFailedFields) {
    highlightFailedFields = typeof(highlightFailedFields) === 'undefined' ? false : highlightFailedFields;

    var section = $('.advert-wizard-section[data-step="' + step + '"]');
    var activeSection = $('.advert-wizard-section.active');
    var activeStep = activeSection.data('step');

    if(highlightFailedFields) {
        $('.fake-form-group-with-error').each(function() {
            $(this).addClass('has-error');
        });

        if($('.fake-form-group-with-error:first').length) {
            var scrollTo = $('.fake-form-group-with-error:first').closest('.form-group').find('label:first');
            if(! scrollTo.length) {
                scrollTo = $('.fake-form-group-with-error:first');
            }

            if(scrollTo.length) {
                scrollToElement(scrollTo);
            }
        }
    }

    if (step > 1 && step > activeStep) {
        for (i = 1; i < step; i++) {
            var previousSection = $('.advert-wizard-section[data-step="' + i + '"]');
            if (previousSection.length && previousSection.hasClass('required') && !previousSection.hasClass('complete')) {
                return false;
            }
        }
    }

    var formToSubmit = activeSection.closest('form');
    if (activeSection.hasClass('complete') && ! formToSubmit.find('input[name="horse_id"]').length) {
        formToSubmit.trigger('submit');
        //if there is no horse id, do not show next section before request is done
        return true;
    } else {
        if(formToSubmit.find('input[name="horse_id"]').length) {
            formToSubmit.trigger('submit');
        }
    }

    $('.advert-wizard-section').not(section).removeClass('active');
    section.addClass('active');
    scrollToElement(section);

    return true;
}

function advertWizardSubmit() {
    if ($(this).hasClass('btn-disabled')) {
        alert('Please complete all required sections first.');
    }
}

function advertWizardValidation() {
    $('.advert-wizard-section').each(function () {
        var section = $(this);
        advertWizardValidateSection(section);
        advertWizardSetSubmitState();

        section.find('input, select, textarea').on('change', function(){
            var section = $(this).closest('.advert-wizard-section');
            advertWizardValidateSection(section);
            advertWizardSetSubmitState();
        });
    });
}

function advertWizardSetSubmitState() {
    var status = true;
    $('.advert-wizard-section').each(function () {
        if ($(this).hasClass('required') && !$(this).hasClass('complete')) {
            status = false;
        }
    });

    if (status) {
		if ($('#push-advert-live').length){
			$('#push-advert-live').modal();

            $('#push-advert-live').on('hidden.bs.modal', function() {
                $('#advert-subscription-form').appendTo('#advert-subscription');
                $('#advert-subscription').removeClass('hidden');
                $('.productPaymentSelect').trigger('change');
                $(this).remove();
            });

			$('#push-advert-live-cancel').on('click',function(){
                $('#push-advert-live').modal('hide');
            });

			$('#push-advert-live-payment-submit').off('click').on('click', function(e) {
			    e.preventDefault();

			    $('#push-advert-live-information').addClass('hidden');
			    $('#push-advert-live-payments').removeClass('hidden');
			    $('#push-advert-live .modal-footer').find('button').addClass('hidden');
			    $('#push-advert-live-payment-cancel').removeClass('hidden');
                $('#advert-subscription').addClass('hidden');
                $('#advert-subscription-form').appendTo('#go-live-now-container');
            });

            $('#push-advert-live-payment-cancel').off('click').on('click', function(e) {
                e.preventDefault();

                $('#push-advert-live-information').removeClass('hidden');
                $('#push-advert-live-payments').addClass('hidden');
                $('#push-advert-live .modal-footer').find('button').removeClass('hidden');
                $('#push-advert-live-payment-cancel').addClass('hidden');
                $('#advert-subscription').removeClass('hidden');
                $('#advert-subscription-form').appendTo('#advert-subscription');
            });
		}
        $('#advert-submit').removeClass('btn-disabled').removeAttr('disabled');
        $('#submit-disabled').hide();
        $('#advert-subscription').removeClass('hidden')
        var subscriptionBox = $('#advert-subscription');
        subscriptionBox.css('width', subscriptionBox.width());
    } else {
        $('#advert-submit').addClass('btn-disabled').attr('disabled', 'disabled');
        $('#submit-disabled').show();
        $('#advert-subscription').addClass('hidden');
    }
}

function advertWizardValidateSection(section) {
    var required = section.hasClass('required');
    var complete = true;
    var checkboxNamesToValidate = [];
    section.find('input, select, textarea').each(function () {
        var input = $(this);

        if(input.attr('name') && ! input.attr('disabled') && ! input.attr('readonly')) {
            if(input.hasClass('required')) {
                var fakeGroup = input.closest('.fake-form-group');
                if(! fakeGroup.length) {
                    input.wrap('<div class="fake-form-group"></div>');
                    fakeGroup = input.closest('.fake-form-group');
                }
                fakeGroup.removeClass('fake-form-group-with-error');
            }

            if (input.attr('type') == 'checkbox') {
                checkboxNamesToValidate.push(input.attr('name'));
            }

            var val = input.val()

            if(input.attr('readonly')) {
                fakeGroup.removeClass('fake-form-group-with-error has-error');
            } else {
                if ((!required || (required && input.hasClass('required'))) && (!val || !val.length)) {
                    if(input.hasClass('required')) {
                        fakeGroup.addClass('fake-form-group-with-error');
                    }

                    complete = false;
                }

                if(val && val.length && fakeGroup){
                    fakeGroup.removeClass('has-error');
                }
            }
        }
    });

    $.each(checkboxNamesToValidate, function (index, value) {
        if (!section.find('input[name="' + value + '"]:checked').length) {
            complete = false;
        }
    });

    if (complete) {
        section.addClass('complete');
    }
    else {
        section.removeClass('complete');
    }

    return complete;
}


function advertWizardFileManager() {
    $(document).off('MediaController:imageRemoved').on('MediaController:imageRemoved', function (event, result) {
        try {
            var imageForUpdate = $('.fn-media-manager[data-position="' + result.position + '"]');
            imageForUpdate.find('img.advert-wizard-picture').attr('src', '');
            imageForUpdate.addClass('hidden');

            $('.fileinput-button[data-position="' + result.position + '"]').removeClass('hidden');

            $('#advert-images-completed').val('');
            if ($('.fn-media-manager:not(.hidden)').length) {
                $('#advert-images-completed').val(1);
            }

            $('#manage-media').modal('hide');

            advertWizardFileManager();
            advertWizardValidation();
        } catch (error) {
            console.log(error);
        }
    });

    $(document).off('MediaController:mediaManagerLoaded').on('MediaController:mediaManagerLoaded', function (event, result) {
        try {
            advertWizardFileManager();
        } catch (error) {
            console.log(error);
        }
    });

    $(document).off('MediaController:imageCropped').on('MediaController:imageCropped', function (event, result) {
        try {
            $('#manage-media').find('.fn-media-manager-picture').attr('src', result.picture);
            var imageForUpdate = $('.fn-media-manager[data-position="' + result.position + '"]');
            imageForUpdate.find('img.advert-wizard-picture').attr('src', result.picture);

            $('.cropper-image-tool').find('img').attr('src', result.large_original_picture);

            $('#manage-media').find('.fn-media-manager-crop-cancel').trigger('click');
        } catch (error) {
            console.log(error);
        }
    });

    $('.fn-advert-image-upload').fileupload({
        dataType: 'json',
        url: '/media/manager/upload',
        done: function (e, data) {
            var result = data.result;
            try {
                switch (result.action) {
                    case 'add':
                        if (result.error) {
                            $('.fn-upload-error').removeClass('hidden').html(result.error)
                        } else {
                            $('.fileinput-button[data-position="' + result.position + '"]').addClass('hidden');
                            var imageForUpdate = $('.fn-media-manager[data-position="' + result.position + '"]');
                            imageForUpdate.find('img.advert-wizard-picture').attr('src', result.picture);
                            imageForUpdate.removeClass('hidden');

                            $('#advert-images-completed').val(1);

                            advertWizardFileManager();
                            advertWizardValidation();
                        }
                        break;
                    case 'change':
                        if (result.error) {
                            $('#manage-media').find('.fn-upload-error').removeClass('hidden').html(result.error)
                        } else {
                            $('#manage-media').find('.fn-media-manager-picture').attr('src', result.picture);
                            var imageForUpdate = $('.fn-media-manager[data-position="' + result.position + '"]');
                            imageForUpdate.find('img.advert-wizard-picture').attr('src', result.picture);

                            $('.cropper-image-tool').find('img').attr('src', result.large_original_picture);

                            advertWizardValidation();
                        }
                        break;
                }
            } catch (error) {
                console.log('Upload Error: ' + error);
            }
        }
    }).off('fileuploadsubmit').on('fileuploadsubmit', function (e, data) {
        $('.fn-upload-error').addClass('hidden').html('');
        var href = $(this).closest('.fileinput-button').data('href');
        data.url = href;

        return true;
    });

    $('#manage-media').find('.fn-media-manager-picture-resize').on('click', function (event) {
        event.preventDefault();
        $('#crop-image').removeClass('hidden');
        $('#crop-actions').removeClass('hidden');

        $('#show-image').addClass('hidden');
        $('#image-actions').addClass('hidden');
        initCropper();
        renderCropperTool();
    });

    $('#manage-media').find('.fn-media-manager-crop-cancel').on('click', function (event) {
        event.preventDefault();
        $('#crop-image').addClass('hidden');
        $('#crop-actions').addClass('hidden');

        $('#show-image').removeClass('hidden');
        $('#image-actions').removeClass('hidden');
    })

    $(document).off('MediaController:videoSaved').on('MediaController:videoSaved', function(event, data) {
        var newSrc = '//img.youtube.com/vi/'+ data.video_id +'/0.jpg';
        $('#manage-media').find('img#video-image').attr('src', newSrc);
        $('#manage-media').find('.fn-media-manager-video-remove').removeClass('hidden');

        var container = $('.fn-media-manager-video[data-position="'+ data.position +'"]');
        var containerEmpty = $('.fn-media-manager-video-empty[data-position="'+ data.position +'"]');

        container.find('img').attr('src', newSrc);
        container.removeClass('hidden');
        containerEmpty.addClass('hidden');

        // $('#advert-videos-completed').val(1);
		$('#manage-media').modal('toggle');
        advertWizardFileManager();
        advertWizardValidation();
    });

    $(document).off('MediaController:videoRemoved').on('MediaController:videoRemoved', function(event, data) {
        $('#manage-media').find('img#video-image').attr('src', '');
        $('#manage-media').find('#video-url').val('');

        $('#manage-media').find('.fn-media-manager-video-remove').addClass('hidden');

        var container = $('.fn-media-manager-video[data-position="'+ data.position +'"]');
        var containerEmpty = $('.fn-media-manager-video-empty[data-position="'+ data.position +'"]');
        container.find('img').attr('src', '');
        container.addClass('hidden');
        containerEmpty.removeClass('hidden');

        // $('#advert-videos-completed').val('');

        // if($('.fn-media-manager-video:not(.hidden)').length) {
        //     $('#advert-videos-completed').val(1);
        // }

        advertWizardFileManager();
        advertWizardValidation();
    });
	
	$('.addMedia').off('click').on('click',function(){
		ele = $(this);
		ele.remove();
		target = $('#'+ele.data('target'));
		target.removeClass('hidden-xs');
		target.find('.click-target').trigger('click');
	});
}

function advertWizardCompetitions() {
    $('select[name="discipline[]"]').off('change').on('change', function() {
        var inst = $(this);
        var selectedValues = inst.val() ? inst.val() : [];

        $('.fn-competition-section').addClass('hidden')
            .find('select,input').attr('disabled', 'disabled');

        $.each(selectedValues, function(index, value) {
            var selectedName = $.trim(inst.find('option[value="'+ value +'"]').html());
            var competitionSectionToUnlock = $('.fn-competition-section[data-competition-name="'+ selectedName +'"]');
            competitionSectionToUnlock.removeClass('hidden');
            competitionSectionToUnlock.find('select,input').removeAttr('disabled');
        });
    }).trigger('change');
}

function advertWizardSwitchToAdvertMode() {
    $('.fn-advertise-horse').on('click', function() {
        $('input[name="entry_type"]').val('advert');
        $('input[name="redirect_after_save"]').val(1);
        $('#advert-form').trigger('submit');
    });
}