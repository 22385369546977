$(document).ready(function() {
    $('.select2').multiselect();

    $('.gender').on('change', function() {
                if($(this).val()=='S')
                    $('.graded').show();
                else
                    $('.graded').hide();
	});

    // to top right away
    if ( window.location.hash ) scroll(0,0);
    // void some browsers issue
    setTimeout( function() { scroll(0,0); }, 1);

    setScroll();

    getBootStrapEnv(route);

    $("#affiliate_id").click(function () {
        $(this).select();
    });

    getLocation();

    searchSimpleFilters();

    productPayment();

    // signupPopupHorseProfile();

    forceTcsAcceptance();

    $('.header-alert').fadeOut(2500);
    renderInstagramFeed();
	
	bindSubmit();
});

function setScroll(){
        // your current click function
        $('.scroll').on('click', function(e) {
            e.preventDefault();
            $('html, body').animate({
                scrollTop: $($(this).attr('href')).offset().top + 'px'
            }, 1000, 'swing');
        });

        // *only* if we have anchor on the url
        if(window.location.hash) {

            // smooth scroll to the anchor id
            $('html, body').animate({
                scrollTop: $(window.location.hash).offset().top + 'px'
            }, 1000, 'swing');
        }

}


function getBootStrapEnv(route){
    var bootStrapEnv = findBootstrapEnvironment();
    // console.log(route);
    if(bootStrapEnv == 'xs' && route != 'home') {
        $('.navbar').addClass('hidden-xs')
    }
}

function findBootstrapEnvironment() {
    var envs = ['xs', 'sm', 'md', 'lg'];

    var $el = $('<div>');
    $el.appendTo($('body'));

    for (var i = envs.length - 1; i >= 0; i--) {
        var env = envs[i];

        $el.addClass('hidden-'+env);
        if ($el.is(':hidden')) {
            $el.remove();
            return env;
        }
    }
}

function renderPurePartSearch(){
            $('.pure_part_breed').change(function () {

                switch($('option:selected', this).val()) {
                    case 'Part Breed':
                        $('.second-breed-select, .first-breed-select').removeClass('hidden');
                        $('.second-breed-select select, .first-breed-select select').prop('selectedIndex', 0);
                        $('.second-breed-select select, .first-breed-select select').val("");

                        break;
                    case 'Pure Breed':
                        $('.first-breed-select').removeClass('hidden');
                        $('.first-breed-select select').prop('selectedIndex', 0);
                        $('.first-breed-select select').val("");

                        $('.second-breed-select').addClass('hidden');

                        break;
                    default:
                        $('.second-breed-select').addClass('hidden');
                        $('.second-breed-select select, .first-breed-select select').prop('selectedIndex', 0);
                        $('.second-breed-select select, .first-breed-select select').val("");
                        break;
                }

            });
}


function renderDisciplineSelectSearch(ids,submittedVal){
        var riderLevels = jQuery('.competition-level-select option');
        var cloneSelect = jQuery('.competition-level-select select option').clone();

        jQuery('select.discipline').change(function () {
            jQuery('.competition-level-select select').multiselect('destroy');

            jQuery('.competition-level-select select').empty();
            var optionName = jQuery(this).find('option:selected').attr("name");

            if(jQuery.inArray(optionName, ids )  != -1 ) {

                if(optionName == 'Jumpers / Show Jumper') {
                    optionName = 'show_jumping';
                }else if(optionName == 'Event') {
                    optionName = 'eventing';
                }

                var selectedId = optionName.toLowerCase();

                cloneSelect.each(function (i, option) {
                    if(jQuery(this).data('group') && jQuery(this).data('group') == selectedId ) {
                        jQuery('.competition-level-select select').append(option);
                    } else if(jQuery(this).val() == '') {
                        //placeholder option
                        jQuery('.competition-level-select select').append(option);
                    }
                });

                jQuery('.competition-level-select select').prop('selectedIndex',0);

                jQuery('.competition-level-select').show();
            }else{
                jQuery('.competition-level-select').hide();
            }

            jQuery('.competition-level-select select').multiselect({
                nonSelectedText: 'Any',
                buttonWidth: '100%',
                includeSelectAllOption: true,
                inheritClass: true,
            }).multiselect('deselectAll', false).multiselect('refresh');
        });

        if(submittedVal && jQuery.inArray(submittedVal, ids) != -1) {
            if(submittedVal == 'Jumpers / Show Jumper') {
                submittedVal = 'show_jumping';
            }else if(submittedVal == 'Event') {
                submittedVal = 'eventing';
            }
            jQuery('.competition-level-select select option').remove();

            cloneSelect.each(function (i, option) {
                if(jQuery(this).data('group') && jQuery(this).data('group') == submittedVal.toLowerCase() ) {
                    jQuery('.competition-level-select select').append(option);
                } else if(jQuery(this).val() == '') {
                    //placeholder option
                    jQuery('.competition-level-select select').append(option);
                }
            });

            jQuery('.competition-level-select').show();

            jQuery('.competition-level-select select').multiselect({
                nonSelectedText: 'Any',
                buttonWidth: '100%',
                includeSelectAllOption: true,
                inheritClass: true,
            });
        };
}


function renderDisciplineSelectProfessional(ids,submittedVal){
    var riderLevels = jQuery('.rider_level option');
    var cloneSelect = jQuery('.rider_level select option').clone();

    jQuery('select.discipline').change(function () {
        jQuery('.rider_level select').empty();
        if(jQuery.inArray(parseInt(this.value), ids )  != -1 ) {
            var selectedId = this.value;
            cloneSelect.each(function (i, option) {
                if(jQuery(this).data('group') && jQuery(this).data('group') == selectedId ) {
                    jQuery('.rider_level select').append(option);
                } else if(jQuery(this).val() == '') {
                    jQuery('.rider_level select').append(option);
                }
            });
            jQuery('.rider_level select').prop('selectedIndex',0);
            jQuery('.rider_level').show();
        }else{
            jQuery('.rider_level').hide();
        }
    });

    if(submittedVal && jQuery.inArray(parseInt(submittedVal), ids) != -1) {
        jQuery('.rider_level select option').remove();
        cloneSelect.each(function (i, option) {
            if(jQuery(this).data('group') && jQuery(this).data('group') == submittedVal ) {
                jQuery('.rider_level select').append(option);
            } else if(jQuery(this).val() == '') {
                jQuery('.rider_level select').append(option);
            }
        });
        jQuery('.rider_level').show();
    };
}

function rederPurePartStallion(){

	$('.pure_part_breed').change(function () {

	    switch ($('option:selected', this).val()) {
	        case 'Part Breed':
	            $('.second-breed-select, .first-breed-select').removeClass('hidden');
	            $('.second-breed-select select, .first-breed-select select').prop('selectedIndex', 0);
	            $('.second-breed-select select, .first-breed-select select').val("");

	            break;
	        case 'Pure Breed':
	            $('.first-breed-select').removeClass('hidden');
	            $('.first-breed-select select').prop('selectedIndex', 0);
	            $('.first-breed-select select').val("");

	            $('.second-breed-select').addClass('hidden');

	            break;
	        default:
	            $('.second-breed-select').addClass('hidden');
	            $('.second-breed-select select, .first-breed-select select').prop('selectedIndex', 0);
	            $('.second-breed-select select, .first-breed-select select').val("");
	            break;
	    }

	});

}


function rednderDisciplineStallion(ids,submittedVal,submittedStudbookVal){
            var riderLevels = jQuery('.competition-level-select option');
            var cloneSelect = jQuery('.competition-level-select select option').clone();

            jQuery('select.discipline').change(function () {

                jQuery('.competition-level-select select').empty();
                var optionName = jQuery(this).find('option:selected').attr("name");

                if (jQuery.inArray(optionName, ids) != -1) {

                    if (optionName == 'Jumpers / Show Jumper') {
                        optionName = 'show_jumping';
                    } else if (optionName == 'Event') {
                        optionName = 'eventing';
                    }

                    var selectedId = optionName.toLowerCase();

                    cloneSelect.each(function (i, option) {
                        if (jQuery(this).data('group') && jQuery(this).data('group') == selectedId) {
                            jQuery('.competition-level-select select').append(option);
                        } else if (jQuery(this).val() == '') {
                            //placeholder option
                            jQuery('.competition-level-select select').append(option);
                        }
                    });

                    jQuery('.competition-level-select select').prop('selectedIndex', 0);

                    jQuery('.competition-level-select').show();
                } else {
                    jQuery('.competition-level-select').hide();
                }

            });


            if (submittedVal && jQuery.inArray(submittedVal, ids) != -1) {

                if (submittedVal == 'Jumpers / Show Jumper') {
                    submittedVal = 'show_jumping';
                } else if (submittedVal == 'Event') {
                    submittedVal = 'eventing';
                }

                jQuery('.competition-level-select select option').remove();

                cloneSelect.each(function (i, option) {
                    if (jQuery(this).data('group') && jQuery(this).data('group') == submittedVal.toLowerCase()) {
                        jQuery('.competition-level-select select').append(option);
                    } else if (jQuery(this).val() == '') {
                        //placeholder option
                        jQuery('.competition-level-select select').append(option);
                    }
                });

                jQuery('.competition-level-select').show();

            }


            $('.country').change(function () {

                switch ($('option:selected', this).val()) {
                    case '':
                        $('.studbooks-container').addClass('hidden');
                        $('.studbooks-container select').prop('selectedIndex', 0);
                        $('.studbooks-container select').val("");

                    default:
                        $('.studbooks-container').removeClass('hidden');
                        $('.studbooks-container select').prop('selectedIndex', 0);
                        $('.studbooks-container select').val("");
                        break;
                }

            });

            var cloneSelectStudbooks = jQuery('.studbooks option').clone();

            jQuery('select.country').change(function () {

                jQuery('.studbooks').empty();
                var optionName = jQuery(this).find('option:selected').attr("value");

                var selectedId = optionName.toLowerCase();

                cloneSelectStudbooks.each(function (i, option) {
                    if (jQuery(this).data('group') && jQuery(this).data('group') == selectedId) {
                        jQuery('.studbooks').append(option);
                    } else if (jQuery(this).val() == '') {
                        //placeholder option
                        jQuery('.studbooks').append(option);
                    }
                });

                jQuery('.studbooks').prop('selectedIndex', 0);

                jQuery('.studbooks-container').removeClass('hidden');


            });


            jQuery('.studbooks option').remove();
            jQuery('.membersstudbook option').remove();

            var memberValueSelected = false;

            cloneSelectStudbooks.each(function (i, option) {
                if (jQuery(this).data('group') && jQuery(this).data('group') == submittedStudbookVal) {
                    jQuery('.studbooks').append(option);
                } else if (jQuery(this).val() == '') {
                    //placeholder option
                    jQuery('.studbooks').append(option);
                }

                if (jQuery(this).data('group') && jQuery(this).data('group') == submittedStudbookVal && jQuery(this).data('members') == 1) {
                    jQuery('.membersstudbook').append(option);
                    memberValueSelected = true;
                } else if (jQuery(this).val() == '' && jQuery(this).val() != 'WBFSH members') {
                    //placeholder option
                    jQuery('.membersstudbook').append(option);
                }
            });

            if (submittedStudbookVal && submittedStudbookVal != '') {
                jQuery('.studbooks-container').removeClass('hidden');

                if(memberValueSelected) {
                    jQuery('.membersstudbooks-container').removeClass('hidden');
                }
            }

            jQuery('select.studbooks').change(function () {

                if($(this).val() != '') {
                    return;
                }

                jQuery('.membersstudbook').empty();

                selectedId = jQuery('.country').find('option:selected').attr("value");


                cloneSelectStudbooks.each(function (i, option) {
                    if (jQuery(this).data('group') && jQuery(this).data('group') == selectedId && jQuery(this).data('members') == 1) {
                        if(jQuery(this).val() != '') {
                            jQuery('.membersstudbook').append(option);
                        }
                    } else if (jQuery(this).val() == 'WBFSH members') {
                        //placeholder option
                        jQuery('.membersstudbook').append(option);
                    }
                });

                jQuery('.membersstudbook').prop('selectedIndex', 0);

                jQuery('.membersstudbooks-container').removeClass('hidden');


            });


}

function initCropper(){
	$('.cropper-image-tool > img').cropper({
	    strict: true,
	    guides: false,
	    highlight: false,
	    dragCrop: true,
	    cropBoxMovable: true,
	    cropBoxResizable: true,
	    modal: true,
	    autoCrop: true,
	    mouseWheelZoom: true,
	    rotatable: true,
	    scalable: true,
	    zoomable: true,
	    viewMode: 2
	});

}

function renderCropperTool(){
    var $image = $('.cropper-image-tool img');

    $('.docs-buttons').on('click', '[data-method]', function () {
        var $this = $(this);
        var data = $this.data();
        var $target;
        var result;

        if ($this.prop('disabled') || $this.hasClass('disabled')) {
            return;
        }

        if ($image.data('cropper') && data.method) {
            data = $.extend({}, data); // Clone a new one

            if (typeof data.target !== 'undefined') {
                $target = $(data.target);

                if (typeof data.option === 'undefined') {
                    try {
                        data.option = JSON.parse($target.val());
                    } catch (e) {
                        console.log(e.message);
                    }
                }
            }

            if (data.method === 'rotate') {
                $image.cropper('clear');
            }

            result = $image.cropper(data.method, data.option, data.secondOption);

            if (data.method === 'rotate') {
                $image.cropper('crop');
            }

            switch (data.method) {
                case 'scaleX':
                case 'scaleY':
                    $(this).data('option', -data.option);
                    break;

            }

            if ($.isPlainObject(result) && $target) {
                try {
                    $target.val(JSON.stringify(result));
                } catch (e) {
                    console.log(e.message);
                }
            }

        }
    });

}

function set_data(){
	$('#image_json').val(JSON.stringify($('.cropper-image-tool > img').cropper("getData", true)));
	$('#crop-form').submit();
}


function renderPurePartBreed(){
      $('.pure_part_breed').change(function () {

         if($('option:selected', this).val() == 'Part Breed') {
            $('.second-breed-select').removeClass('hidden');
            $('.second-breed-select select').prop('selectedIndex',0);
            $('.second-breed-select select').val("");
         }else{
            $('.second-breed-select').addClass('hidden');
         }

      });

      if($('.pure_part_breed').val() == 'Part Breed') {
         $('.second-breed-select').removeClass('hidden');
      }
}

function initMultiSelect(){
	$('.select select').multiselect(
	        {
	            buttonWidth: '300px',
	            enableFiltering: true,
	            enableCaseInsensitiveFiltering: true,
	            maxHeight: 300
	        }
	);
}

function renderProfessionalServices(){
	$('.add-professional').click(function () {
		$('.professional_services').removeClass('hidden');
	});

	$('.professional_services').change(function () {
		$('input[name=profile_type_id]', '.add_professional').val($(this).val());
		$('.add_professional').submit();
	})
}

function renderPurePartMyHorse(){
	 $('.pure_part_breed').change(function () {

	    switch($('option:selected', this).val()) {
	       case 'Part Breed':
	          $('.second-breed-select, .first-breed-select').removeClass('hidden');
	          $('.second-breed-select select, .first-breed-select select').prop('selectedIndex', 0);
	          $('.second-breed-select select, .first-breed-select select').val("");
	          break;
	       case 'Pure Breed':
	          $('.first-breed-select').removeClass('hidden');
	          $('.first-breed-select select').prop('selectedIndex', 0);
	          $('.first-breed-select select').val("");
	          break;
	       case 'Any':
	          $('.second-breed-select, .first-breed-select').addClass('hidden');
	          $('.second-breed-select select, .first-breed-select select').prop('selectedIndex', 0);
	          $('.second-breed-select select, .first-breed-select select').val("");
	          break;
	    }

	 });

	 if($('.pure_part_breed').val() == 'Part Breed') {
	    $('.second-breed-select, .first-breed-select').removeClass('hidden');
	    $('.second-breed-select select, .first-breed-select select').prop('selectedIndex', 0);
	    $('.second-breed-select select, .first-breed-select select').val("");
	 }
}


function renderEqualHeight(){
		var equalheight = function(container){

			var currentTallest = 0,
					currentRowStart = 0,
					currentTallestBottomDiv = 0,
					rowDivs = new Array(),
					bottomDivs = new Array(),
					$el,
					topPosition = 0;
			$(container).each(function() {

				$el = $(this);
				$($el).height('auto')
				topPostion = $el.position().top;

				if (currentRowStart != topPostion) {
					for (currentDiv = 0 ; currentDiv < rowDivs.length ; currentDiv++) {
						rowDivs[currentDiv].height(currentTallest + currentTallestBottomDiv);
					}
					rowDivs.length = 0; // empty the array
					currentRowStart = topPostion;
					currentTallest = $el.height();
					currentTallestBottomDiv = $el.find('.bottom').height();
					rowDivs.push($el);
				} else {
					rowDivs.push($el);
					currentTallest = (currentTallest < $el.height()) ? ($el.height()) : (currentTallest);
					currentTallestBottomDiv = (currentTallestBottomDiv < $el.find('.bottom').height()) ? ($el.find('.bottom').height()) : (currentTallestBottomDiv);
				}


				for (currentDiv = 0 ; currentDiv < rowDivs.length ; currentDiv++) {
					rowDivs[currentDiv].height(currentTallest + currentTallestBottomDiv);
				}
			});
		}

		$(window).load(function() {
			equalheight('.row1');
			equalheight('.row-2');
		});


		$(window).resize(function(){
			equalheight('.row1');
			equalheight('.row-2');
		});

}

function getLocation() {
    $('#location').on('focus', function () {
        if($(this).val() == '') {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(getLocationName);
            }
        }
    });
}

function getLocationName(position)
{
    $.get('/ajax/location_name', {
        'latitude' : position.coords.latitude,
        'longitude' : position.coords.longitude
    }, function (response) {
        $('#location').val(response.location);
    }, 'json');
}

function searchSimpleFilters()
{
    $('.simpleFilter').on('click', function() {
        var inst = $(this);
        inst.toggleClass('active');
        $('.search_results_row_' + inst.data('search-type')).toggleClass('hidden');
    });
}

function productPayment()
{
    $('.productPaymentSelect').off('change').on('change', function() {
        $('.productPaymentForm,.productPaymentDescription,.paymentRequestButton').addClass("hidden")
        $('.productPaymentForm[data-product-id="'+ $(this).val() +'"]').removeClass('hidden')
        $('.productPaymentDescription[data-product-id="'+ $(this).val() +'"]').removeClass('hidden')
        $('.paymentRequestButton#payment-request-button-'+$(this).val()).removeClass('hidden');
    })

    $('.fn-cancel-advert').on('click', function(event) {
        event.preventDefault();
        $(this).addClass('hidden');
        $('#cancellation-reasons').removeClass('hidden');
    });
}

function signupPopupHorseProfile()
{
    var signupPopup = {
        modalSelector: $("#signup-popup-horse-profile-modal"),
        init: function() {
            var self = this;
            if(self.modalSelector.length) {
                self.bindFunctions();
            }
        },

        bindFunctions: function() {
            var self = this;

            self.showOnScroll();
            self.showOnSignUpErrors();
            self.userSubscriptionSaved();
            self.setSubscriptionToResolve();
            self.resolveSubscription();
            self.showPhoneNumber();
        },

        showOnSignUpErrors: function() {
            var self = this;
            if($('.fn-signup-popup-horse-errors').length) {
                self.showModal();
            }
        },

        showModal: function() {
            var self = this;
            self.modalSelector.modal({
                show: true,
                backdrop: 'static',
                keyboard: false
            });
        },

        showOnScroll: function() {
            var self = this;
			
			if (self.modalSelector.hasClass('subscribed'))
				return;
			
            $(window).scroll(function (event) {
                if(self.allowedToShowOnScroll()) {
                    var scroll = $(window).scrollTop();
                    var height = $('body').height();

                    if(scroll > height / 2) {
                        self.showModal();
                        var cookie = self.getSubscriptionsToResolve();
                        cookie.popupShowed = 1;
                        $.cookie.json = true;
                        $.cookie('signup_popup_horse_profile', cookie, { path: '/' });
                    }
                }
            });
        },

        allowedToShowOnScroll: function() {
            var self = this;
            var cookie = self.getSubscriptionsToResolve();
            return typeof cookie.popupShowed === 'undefined' || cookie.popupShowed != 1;
        },

        userSubscriptionSaved: function() {
            var self = this;
            $(document).off('MembersHorseController:similarHorsesSubscribed.signupPopupHorseProfile')
                .on('MembersHorseController:similarHorsesSubscribed.signupPopupHorseProfile', function(event, data) {
                    self.modalSelector.find('.fn-signup-container').addClass('hidden');
                    self.modalSelector.find('.fn-signup-success').removeClass('hidden');
                });
        },

        getSubscriptionsToResolve: function() {
            $.cookie.json = true;
            var cookie = $.cookie('signup_popup_horse_profile');
            if(typeof cookie === 'object') {
                return cookie;
            }

            return {};
        },

        setSubscriptionToResolve: function() {
            var self = this;
            $('input#subscribe-similar-horses').on('click', function() {
                var inst = $(this);
                var checked = inst.is(':checked');
                var horseId = inst.data('horse-id');
                var subscriptionsToResolve = self.getSubscriptionsToResolve();
                if(typeof subscriptionsToResolve.horse === 'undefined') {
                    subscriptionsToResolve.horse = null;
                }

                if(checked) {
                    subscriptionsToResolve.horse = horseId;
                } else {
                    subscriptionsToResolve.horse = null;
                }

                $.cookie.json = true;
                $.cookie('signup_popup_horse_profile', subscriptionsToResolve, { path: '/' });
            });
        },

        resolveSubscription: function() {
            var self = this;
            var subscriptionsToResolve = self.getSubscriptionsToResolve();

            if(
                typeof userSignedIn !== 'undefined'
                && userSignedIn == 1
                && typeof subscriptionsToResolve.horse !== 'undefined'
                && subscriptionsToResolve.horse !== null
            ) {
                Core.ajaxRequest({url: '/member/horse/'+ subscriptionsToResolve.horse +'/subscribe-similar-horses', data: {}});
                subscriptionsToResolve.horse = null;
                $.cookie.json = true;
                $.cookie('signup_popup_horse_profile', subscriptionsToResolve, { path: '/' });
            }
        },

        showPhoneNumber: function() {
            var self = this;
			
			if (self.modalSelector.hasClass('subscribed'))
				return;
			
            $('.fn-horse-profile-show-phone-number').on('click', function() {
                $('.fn-horse-profile-show-phone-number').addClass('hidden');
                $('.fn-horse-profile-phone-number').removeClass('hidden');
                self.showModal();
            });
        }
    }

    signupPopup.init();

}

function bindSubmit(){ 
	$('.fn-submit').off('click').on('click',function(){
		var ele = $(this);
		if(!ele.hasClass('btn-muted')){
			ele.addClass('btn-muted').prop('disabled',true);
			ele.parent('form').submit();
		}
	});
}

function forceTcsAcceptance()
{
    var tcsInstances = $('.fn-accept-tcs');

    if(tcsInstances.length) {
        var blockedForms = tcsInstances.closest('form');
        blockedForms.off('submit.forceTcsAcceptance').on('submit.forceTcsAcceptance', function(event) {
            var inst = $(this);
            var tcs = inst.find('.fn-accept-tcs');

            if(! tcs.is(':checked')) {
                event.preventDefault();
				$('.fn-submit').removeClass('btn-muted').prop('disabled',false);
                alert('T&Cs agreement has to be checked');
            }
        });
    }
}

function renderInstagramFeed() {
    if($('.fn-instagram-feed-container').length) {
        Core.ajaxRequest({url: '/instagram-feed', 'method': 'get'});
    }
}
